/**
 * Enum of possible request action use in redux status to understand which is the request and the relate result.
 * @readonly
 * @enum {string}
 */
export const ACTION_REQUEST = Object.freeze({
    post: 'POST',
    get: 'GET',
    delete: 'DELETE',
    put: 'PUT',
});

/**
 * Enum of snackbar level.
 * @readonly
 * @enum {string}
 */
export const SNACKBAR_LEVEL = Object.freeze({
    success: 'success',
    info: 'info',
    error: 'error',
    warning: 'warning'
});

/* ************************************************ SEARCH CONSTANT ************************************************* */
export const TABLE_ROWS_PER_PAGE = Object.freeze([10, 20, 30, 40, 50]);
export const VIEWS_WITH_TABLE = Object.freeze([2, 3, 4]);
export const MAP_VIEWS_WITH_TABLE = Object.freeze({
    products: 4,
    assets_sm: 3,
    assets: 2
});
export const VIEWS_WITHOUT_TABLE = Object.freeze([0, 1]);

export const PRODUCT_COLUMNS_NAME_TO_TRANSLATE = Object.freeze(["pim_status"]);

/**
 * Enum of possible actions that user can perform, based on his roles, in assets table view (2, 3).
 * @readonly
 * @enum {string}
 */
export const SEARCH_ASSETS_ACTIONS = Object.freeze({
    downloadImages: "downloadImages",
    downloadExcelWithImages: "downloadExcelWithImages",
    downloadExcel: "downloadExcel",
    deleteAssets: "deleteAssets",
    approve: "approve",
    reject: "reject",
    reset: "reset",
    migrateAssets: "migrateAssets"
})
/**
 * Enum of possible request action use in redux status to understand which is the request and the relate result.
 * This values are used only for ProductShowroom application.
 * @readonly
 * @enum {string}
 */
export const SEARCH_ACTION_REQUEST = Object.freeze({
    postDownloadExcel: 'POST - EXCEL',
    postDownloadZip: 'POST - ZIP',
    postDownloadInfo: 'POST - INFO',
    postApprove: 'POST - APPROVE',
    postReset: 'POST - RESET',
    postReject: 'POST - REJECT',
    postMigrate: 'POST - MIGRATE',
    getVM: 'GET - VM',
    getCompareInfo: 'GET - COMPARE'
});

/**
 * Enum of possible view type in search application.
 * @readonly
 * @enum {string}
 */
export const SEARCH_VIEWS = Object.freeze({
    products: 'product',
    assets: 'asset',
    assets_sm: 'asset-sm'
});

/**
 * Enum for search application.
 * @readonly
 * @enum {string}
 */
export const SEARCH_APPLICATION = Object.freeze({
    productShowroom: 'productShowroom',
    search: 'search',
    sessioniOrfane: 'sessioniOrfane',
    editGallery: 'editGallery'
});

/**
 * This is the list of possible action used to populate dropdown actions based on current tab selected by user.
 * @readonly
 * @type {({actionLabelDefault: string, actionLabelId: string, actionValue: string, actionKey: string, isAction: boolean, viewsAllowed: number[]}|{applicationLabelDefault: string, applicationLabelId: string, applicationValue: string, applicationKey: string, isAction: boolean, viewsAllowed: number[]})[]}
 */
export const DROPDOWN_SEARCH_ACTIONS = Object.freeze([
    {
        isAction: true,
        actionKey: "search.download_xlsx",
        actionValue: SEARCH_ASSETS_ACTIONS.downloadExcelWithImages,
        actionLabelId: "search.button.label.download.excel_with_images",
        actionLabelDefault: "Export Excel With Images",
        viewsAllowed: [0, 1, 2, 3]
    },
    {
        isAction: true,
        actionKey: "search.download_xlsx",
        actionValue: SEARCH_ASSETS_ACTIONS.downloadExcel,
        actionLabelId: "search.button.label.download.excel",
        actionLabelDefault: "Export Excel",
        viewsAllowed: [0, 1, 2, 3]
    },
    {
        isAction: true,
        actionKey: "search.download_zip",
        actionValue: SEARCH_ASSETS_ACTIONS.downloadImages,
        actionLabelId: "search.button.label.download.images",
        actionLabelDefault: "Download Assets",
        viewsAllowed: [0, 1, 2, 3]
    },
    {
        isAction: true,
        actionKey: "search.delete_assets",
        actionValue: SEARCH_ASSETS_ACTIONS.deleteAssets,
        actionLabelId: "search.button.delete.assets",
        actionLabelDefault: "Delete Assets",
        viewsAllowed: [0, 1, 2, 3]
    },
    {
        isAction: true,
        actionKey: "search.migrate_assets",
        actionValue: SEARCH_ASSETS_ACTIONS.migrateAssets,
        actionLabelId: "search.button.migrate.assets",
        actionLabelDefault: "Migrate assets",
        viewsAllowed: [0, 1, 2, 3]
    },
    {
        isAction: false,
        applicationKey: "approval",
        applicationValue: SEARCH_ASSETS_ACTIONS.approve,
        applicationLabelId: "search.button.approval",
        applicationLabelDefault: "Approve",
        viewsAllowed: [0, 1, 2, 3]
    },
    {
        isAction: false,
        applicationKey: "approval",
        applicationValue: SEARCH_ASSETS_ACTIONS.reject,
        applicationLabelId: "search.button.reject",
        applicationLabelDefault: "Reject",
        viewsAllowed: [0, 1, 2, 3]
    },
    {
        isAction: false,
        applicationKey: "approval",
        applicationValue: SEARCH_ASSETS_ACTIONS.reset,
        applicationLabelId: "search.button.reset",
        applicationLabelDefault: "Reset",
        viewsAllowed: [0, 1, 2, 3]
    }
]);

/**
 * This is the list of possible action used to populate dropdown in unrecognized photo session application
 * @readonly
 * @type {({actionLabelDefault: string, actionLabelId: string, actionValue: string, actionKey: string, isAction: boolean, viewsAllowed: number[]}|{applicationLabelDefault: string, applicationLabelId: string, applicationValue: string, applicationKey: string, isAction: boolean, viewsAllowed: number[]})[]}
 */
 export const DROPDOWN_UNRECOGNIZED_ACTIONS = Object.freeze([
    {
        actionValue: SEARCH_ASSETS_ACTIONS.downloadExcel,
        actionLabelId: "common.button.label.download.excel",
        actionLabelDefault: "Download Excel"
    },
    {
        actionValue: SEARCH_ASSETS_ACTIONS.downloadImages,
        actionLabelId: "common.button.label.download.images",
        actionLabelDefault: "Export Images"
    },
    {
        actionValue: SEARCH_ASSETS_ACTIONS.deleteAssets,
        actionLabelId: "common.button.label.delete",
        actionLabelDefault: "Delete"
    }
]);

/**
 * This constant contains keys used for Detailed view into SearchResultsContainer.js.
 * @readonly
 * @type {{default: string, translateKey: string, key: string}[]}
 */
export const KEYS_DETAILED_VIEW = Object.freeze([
    { translateKey: "product.asset.filename", default: "Name", key: "filename" },
    { translateKey: "product.style_fabric_color", default: "Product", key: "style_fabric_color" },
    { translateKey: "product.richiedente", default: "Richiedente", key: "richiedente" },
    { translateKey: "product.season", default: "Season", key: "season_description" },
    { translateKey: "product.asset.date_hour_shoot", default: "Shoot date", key: "date_hour_shoot" },
    { translateKey: "product.asset.submitter", default: "Submitter", key: "submitter" },
    { translateKey: "product.service", default: "Service", key: "service" },
    { translateKey: "product.l3_product", default: "L3 Product", key: "l3_product" },
    { translateKey: "product.asset.resolution", default: "Resolution", key: "resolution" },
    { translateKey: "product.asset.dimension", default: "Dimension", key: "dimension" },
    { translateKey: "product.brand", default: "Brand", key: "brand" },
    { translateKey: "product.gender", default: "Gender", key: "gender" },
]);

/**
 * This constant contains tabs info.
 * @readonly
 * @type {{default: string, translateKey: string}[]}
 */
export const MENU_TABS = Object.freeze([
    { translateKey: "search.tab.compact.view", default: "Compact" }, // 0
    { translateKey: "search.tab.detail.view", default: "Detail" }, // 1
    { translateKey: "search.tab.table.assets.view", default: "Table Assets" }, // 2
    { translateKey: "search.tab.table.assets.sm.view", default: "Table Assets SM" }, // 3
    { translateKey: "search.tab.table.products.view", default: "Table Product" }, // 4
]);

/**
 * Enum of possible value of status key of each asset.
 * @readonly
 *
 */
export const ASSET_STATUS = Object.freeze({
    taken: "Scattata",
    toPostProduce: "Da post produrre",
    postProducedNew: "Post prodotta nuova",
    postProducedApproved: "Post prodotta approvata",
    postProducedRejected: "Post prodotta rifiutata"
});

/**
 * Enum of asset status to call be for Comparing Dialog into SearchResultsContainer.js
 * @type {{toPostProduce: string, postProduceNew: string}}
 */
export const COMPARING_DIALOG_ASSET_STATUS = Object.freeze({
    toPostProduce: "dapostprodurre",
    postProduceNew: "postprodottanuova"
});
/* ****************************************************************************************************************** */

/* ********************************************* POST PRODUCTION CONSTANT ******************************************* */
/**
 * Enum of possible request that can be performed by postProduction container.
 * @readonly
 * @enum {string}
 */
export const POST_PRODUCTION_ACTIONS_REQUEST = Object.freeze({
   sendEmail: "POST - SEND EMAIL",
   getAssets: "POST - FETCH ASSETS"
});

export const CAROUSEL_ITEMS_PER_PAGE = 24;
/* ****************************************************************************************************************** */

/* ******************************************** PRODUCT-SHOWROOM CONSTANT ******************************************* */
/**
 * Enum of possible request action use in redux status to understand which is the request and the relate result.
 * This values are used only for ProductShowroom application.
 * @readonly
 * @enum {string}
 */
export const PRODUCT_SHOWROOM_ACTION_REQUEST = Object.freeze({
    postProductOrder: 'POST - PRODUCTS ORDER',
    getMacroAreas: 'GET - MACRO AREAS',
    postMacroAreas: 'POST - MACRO AREAS',
    postOrderValues: 'POST - ORDER VALUES',
    getMacroAreasValues: 'GET - MACRO AREAS VALUES',
    postSwitchGalleryOrder: 'POST - SWITCH GALLERY ORDER',
    getExcelUrl: 'GET - EXCEL URL'
});

/**
 * Enum of possible message show by snackbar to clear the correct redux status if necessary.
 * @readonly
 * @enum {string}
 */
export const SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM = Object.freeze({
    getProductsGallery: 'getProductsGallery',
    getMacroAreas: 'getMacroAreas',
    setMacroAreasValues: 'setMacroAreasValues',
    putProductsGallery: 'putProductsGallery',
    updateProductsOrder: 'updateProductsOrder',
    getProductDetails: 'getProductDetails',
    exportProductGallery: 'exportProductGallery',
    switchSortingGallery: 'switchSortingGallery',
    getExcelUrl: 'getExcelUrl',
    newGallery: 'newGallery',
    deleteGallery: 'deleteGallery'
});

/**
 * Enum DND DraggableId.
 * @readonly
 * @enum {string}
 */
export const DNDZONE = Object.freeze({
    filtersKey: 'filtersKey',
    selectedFiltersKey: 'selectedFiltersKey',
    startMacroAreasValues: 'startMacroAreasValues',
    endMacroAreasValues: 'endMacroAreasValues',
});

/**
 * Enum of possible status received from backend of each product for product gallery.
 * @readonly
 * @enum {string}
 */
export const PRODUCT_STATUS_SHOWROOM = Object.freeze({
    new: 'NEW',
    unchanged: 'UNCHANGED',
    missingVariant: 'MISSING_VARIANT',
    deleted: 'DELETED'
});

/**
 * Enum of possible status received from backend of each product for composition gallery.
 * @readonly
 * @enum {string}
 */
export const COMPOSITION_STATUS_SHOWROOM = Object.freeze({
    complete: 'COMPLETE',
    notValid: 'NOT_VALID',
    wrongContext: 'WRONG_CONTEXT',
    placeholder: 'PLACEHOLDER',
    placeholderFromBarcode: 'PLACEHOLDER_FROM_BARCODE'
});

/**
 * Enum of possible documentation resources types
 * @type {Readonly<{products_reports: string, assets_reports: string, docs: string}>}
 */
export const DOCUMENTATION_RESOURCE_TYPE = Object.freeze({
    docs: "DOCS",
    products_reports: "PRODUCTS_REPORTS",
    assets_reports: "ASSETS_REPORTS",
    deleted_assets_reports: "DELETED_ASSETS_REPORTS",
    videos: "VIDEOS"
});
/* ****************************************************************************************************************** */